<template>
    <div>      
      <b-modal
          id="modal-form"
          ok-only
          centered
          size="md"
          content-class="shadow"
          hide-footer
        >
        <template #modal-title> <div style="font-size:24px; font-weight:600;">{{createMode ? 'Tambah' : 'Ubah'}} Instansi</div></template>
          <div >
            <b-form-row>
              <b-col>
                <b-row class="d-flex align-items-center">
                  <b-col sm="4" >
                    <p style="font-weight: 600; margin:0px;">Nama Instansi</p>
                  </b-col>:<b-col sm="7">
                      <b-form-input v-model="dataSubmit.nama" placeholder="Masukan Nama Instansi"></b-form-input>
                  </b-col>
                </b-row>  
                <b-row class="d-flex align-items-center mt-2">
                  <b-col sm="4" >
                    <p style="font-weight: 600; margin:0px;">Email</p>
                  </b-col>:<b-col sm="7">
                      <b-form-input v-model="dataSubmit.email" placeholder="Masukan Email Instansi"></b-form-input>
                  </b-col>
                </b-row>  
                <b-row class="d-flex align-items-center mt-2">
                  <b-col sm="4" >
                    <p style="font-weight: 600; margin:0px;">No Telepon</p>
                  </b-col>:<b-col sm="7">
                      <b-form-input v-model="dataSubmit.nomorTelepon" placeholder="Masukan Email Instansi"></b-form-input>
                  </b-col>
                </b-row>  
                <b-row class="d-flex align-items-center mt-2">
                  <b-col sm="4" >
                    <p style="font-weight: 600; margin:0px;">Propinsi</p>
                  </b-col>:<b-col sm="7">
                      <b-form-select v-model="selectedPropinsi">
                          <b-form-select-option value="" disabled>Silahkan masukkan propinsi</b-form-select-option>
                          <b-form-select-option :value="item.kode" v-for="(item,index) in listPropinsi" :key="index">{{item.nama}}</b-form-select-option>
                        </b-form-select>
                  </b-col>
                </b-row>  
                <b-row class="d-flex align-items-center mt-2">
                  <b-col sm="4" >
                    <p style="font-weight: 600; margin:0px;">Kabupaten/Kota</p>
                  </b-col>:<b-col sm="7">
                      <b-form-select v-model="dataSubmit.kabupatenKotaKode">
                        <b-form-select-option value="" disabled>Silahkan masukkan Kabupaten/kota</b-form-select-option>
                          {{ listKota ? '' : ''}}
                          <b-form-select-option :value="item.kode" v-for="(item,index) in listKota" :key="index">{{item.nama}}</b-form-select-option>
                      </b-form-select>
                  </b-col>
                </b-row>  
              </b-col>
            </b-form-row>
            <div class="text-center">
              <b-button
                variant="outline-primary"
                class="mt-16 text-center mr-5"
                @click="$bvModal.hide('modal-form')"
              >
                Batal
              </b-button>
              <b-button
                variant="primary"
                class="mt-16 text-center"
                @click="submitForm()"
              >
                {{createMode ? 'Tambah' : 'Ubah'}}
              </b-button>
            </div>
          </div>
      </b-modal>
      <b-modal
        id="formKonfirmasi"
        ok-only
        centered
        size="md"
        content-class="shadow"
        hide-header
        hide-footer
      >
        <div>
          <b-container>
            <p style="font-size:18px; margin-top:20px;">
              Apakah anda yakin ingin Menghapus Instansi?
            </p>
          </b-container>
          <div class="text-center">
            <b-button
              variant="outline-secondary"
              class="mt-16 text-center mr-5"
              @click="$bvModal.hide('formKonfirmasi')"
            >
              Tidak
            </b-button>
            <b-button
              variant="danger"
              class="mt-16 text-center"
              @click="deleteData()"
            >
              Yakin
            </b-button>
          </div>
        </div>
      </b-modal>
      <b-row>
        <b-col cols="12">
          <!-- <b-card class="shadow" id="search-card"> -->
            <b-row>
            <b-col cols="12">
              <!-- <div class="form-row"> -->
                <div class="d-flex justify-content-between align-items-center p-1 shadow" style="background:white; border-radius:10px;">
                  <input
                    type="text"
                    class="form-control border-0"
                    :placeholder="'Cari...'"
                    v-model="search"
                    v-on:keyup.enter="searching()"
                  />
                  <b-button
                    size="sm"
                    style="width:80px;margin-right:10px;"
                    variant="primary"
                    @click="searching()"
                  >
                    <v-icon
                      style="color:white;"
                      small
                    >mdi-magnify</v-icon>
                    Cari
                  </b-button>
                </div>
              <!-- </div> -->
            </b-col>
          </b-row>
          <!-- </b-card> -->
        </b-col>
      </b-row>
      <b-row class="mt-5">
          <b-col class="d-flex align-items-center justify-content-between">
            <span style="font-size:16px; font-weight:bold;">Jenis Instansi</span>
            <div>
                <b-button
                size="sm"
                variant="primary"
                @click="$bvModal.show('modal-form')"
              >
                <v-icon
                  style="color:white;"
                  small
                >mdi-plus-box</v-icon>
                Tambah Instansi
              </b-button>
            </div>
          </b-col>
        </b-row>
        <div
            class="table-responsive mt-4"
            style="border-radius:15px;"
          >
            <table
              class="
            table
            table-head-custom
            table-vertical-center
            table-head-bg
            table-bordered
          "
              style="font-size:14px !important;"
            >
              <thead>
                <tr class="text-left">
                  <th style="width: 20px" class="text-capitalize">No.</th>
                  <th style="min-width: 50px" class="text-capitalize">Nama Instansi</th>
                  <th style="min-width: 50px" class="text-capitalize">Aksi</th>
                </tr>
              </thead>
              <!-- <tbody v-if="list.length == 0">
                  <template>
                      <tr>
                          <td colspan="6"> -- Tidak ada permohonan -- </td>
                      </tr>
                  </template>
              </tbody> -->
              <tbody style="background: #FFFFFF;">
                <template v-for="(item, i) in paginateData">
                  <tr v-bind:key="i">
                    <td >
                      <span class="
                      text-dark-75
                      font-weight-bolder
                      d-block
                      font-size-lg
                    ">{{ (i+1) + ((currentPage-1)*perPage)}}</span>
                    </td>
                    <td class="text-left">
                      <span  class="
                      text-dark-75
                      font-weight-bolder
                      text-left
                      font-size-lg
                    "> {{item.nama}}
                      </span>
                    </td>
                    <td>
                      <section>
                        <b-button variant="danger" size="sm" style="padding:5px" class="mr-1" @click="confirmDelete(item.id)">
                            <v-icon style="color:white;padding:0px;" small>mdi-trash-can-outline</v-icon>
                        </b-button>
                        <b-button variant="primary" size="sm" style="padding:5px" class="mr-1" @click="editJenis(item)">
                            <v-icon style="color:white;padding:0px;" small>mdi-pencil</v-icon>
                        </b-button>
                      </section>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <b-row>
            <b-col class="d-flex justify-content-end">
              <b-pagination
                v-model="currentPage"
                :total-rows="listInstansi.length"
                :per-page="perPage"
                @change="fetchData()"
                aria-controls="my-table"
              ></b-pagination>
            </b-col>
          </b-row>
    </div>
</template>
<script>
import Swal from "sweetalert2";
import { removeNullProperties } from "./../../../../myUtils";
export default {
  name: "Informasi",
  data() {
    return {
      listInstansi: [],
      currentPage: 1,
      perPage: 25,
      paginateData: [],
      dataSubmit: {
        "nama": "",
        "email": "",
        "nomorTelepon": "",
        "provinsiKode": "",
        "kabupatenKotaKode": "",
        "terhapus": true
      },
      idnya: null,
      listPropinsi: null,
      listKota: null,
      selectedPropinsi: null,
      createMode: true,
      search: '',
      uri: ''
    };
  },
  mounted() {
    this.fetchData()
    this.getPropinsi()
  },
  watch:{
    selectedPropinsi(to){
      this.getKota(to)
    },
    search: {
      handler() {
        // Check if the user has stopped typing
        if (this.typingTimeout) {
          clearTimeout(this.typingTimeout);
        }
        this.typingTimeout = setTimeout(() => {
          // User has stopped typing, perform your action here
          this.searching()

        }, 500); // Adjust the delay as needed (1 second in this example)
      },
      immediate: false, // Set to true if you want to trigger it immediately
    }
  },
  methods: {
    searching(){
      var params =  {
        "offset": 0,
        "limit": 5000,
        "order": "id desc",
        "where": {
          "nama": {"ilike": "%"+ this.search.trim() +"%"}
        }
      }

      if (this.search.length > 0 && this.search.length < 3) return;

      this.uri = "?filter=" + encodeURIComponent(JSON.stringify(params))
      this.fetchData()
    },
    createJenis(){
      this.createMode = true
      this.dataSubmit = ''
      this.$bvModal.show('modal-form')
    },
    editJenis(data){
      this.idnya = data.id
      this.createMode = false
      this.dataSubmit =  {
        "nama": data.nama,
        "email": data.email,
        "nomorTelepon": data.nomorTelepon,
        "provinsiKode": data.provinsiKode,
        "kabupatenKotaKode": data.kabupatenKotaKode,
      },
      this.selectedPropinsi = data.provinsiKode
      this.$bvModal.show('modal-form')
    },
    fetchData(){
      this.$store
        .dispatch("instansiList", this.uri)
        .then((res) => {
          this.listInstansi = res.data
          this.paginate()
        })
    },
    paginate() {
      // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
      this.paginateData = this.listInstansi.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage);
    },
    submitForm(){
      this.dataSubmit.provinsiKode = this.selectedPropinsi

      if (this.dataSubmit.kabupatenKotaKode == null || this.dataSubmit.kabupatenKotaKode.length == 0) {
        delete this.dataSubmit.kabupatenKotaKode
      }

      this.dataSubmit = removeNullProperties(this.dataSubmit);

      if(this.createMode){
        this.$store
        .dispatch("submitInstansi", this.dataSubmit)
        .then(() => {
          Swal.fire({
            title: "Data berhasil disimpan",
            text: "",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Lanjut",
          }).then(() => {
            this.currentPage = 1
            this.$bvModal.hide('modal-form')
            this.resetForm()
            this.fetchData()
          })
        })
      }else {
        this.$store
        .dispatch("submitFetchInstansi", [this.dataSubmit, this.idnya])
        .then(() => {
          Swal.fire({
            title: "Data berhasil diubah",
            text: "",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Lanjut",
          }).then(() => {
            this.currentPage = 1
            this.$bvModal.hide('modal-form')
            this.resetForm()
            this.fetchData()
          })
        })
      }
      
    },
    resetForm(){
      this.dataSubmit = {
              "nama": "",
              "email": "",
              "nomorTelepon": "",
              "provinsiKode": "",
              "kabupatenKotaKode": "",
              "terhapus": true
            }
    },
    deleteData(){
      this.$store
        .dispatch("deleteInstansi", this.idnya)
        .then(() => {
          Swal.fire({
            title: "Data berhasil dihapus",
            text: "",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Lanjut",
          }).then(() => {
            this.$bvModal.hide('formKonfirmasi')
            this.fetchData()
          })
        })
    },
    confirmDelete(id){
      this.idnya = id
      this.$bvModal.show('formKonfirmasi')
    },
    getPropinsi(){
      this.$store
        .dispatch("getPropinsi")
        .then((res) => {
          
           this.$nextTick(() => {
            this.listPropinsi = res.data.data
          })
          })
    },
    getKota(id){
      this.$store
        .dispatch("getKabupatenKota", id)
        .then((res) => {
          
          this.$nextTick(() => {
            this.listKota = res.data.data
          })
          })
    },
  },
  computed: {
    
  }
};
</script>