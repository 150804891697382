export function removeNullProperties(obj) {
  for (var propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName];
    }
  }

  return obj;
}

export function isValidDate(dateString) {
  // Regular expression to match common date formats
  const datePattern = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\.(\d{3})Z$/;

  // Check if the string matches the date pattern
  const matches = dateString.match(datePattern);

  if (!matches) {
    return false;
  }

  // Destructure the matched groups
  const [, year, month, day, hours, minutes, seconds, milliseconds] = matches;

  // Try to create a Date object from the matched groups
  const date = new Date(
    `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`
  );

  // Check if the Date object is valid
  return !isNaN(date.getTime());
}

export function leapingDate(leapValue) {
  let separateValue = leapValue.split(' ');
  let currentDate = new Date();

  if (separateValue.length !== 2)
    return null;

  if (typeof parseInt(separateValue[0]) != 'number')
    return null;

  switch (separateValue[1]) {
    case 'day':
    case 'days':
      currentDate.setDate(new Date().getDate() + (parseInt(separateValue[0])))
      break;
    case 'month':
    case 'months':
      currentDate.setMonth(new Date().getMonth() + (parseInt(separateValue[0])))
      break;
    case 'year':
    case 'years':
      currentDate.setFullYear(new Date().getFullYear() + (parseInt(separateValue[0])))
      break;
    default:
      return null
  }

  return currentDate;
}
